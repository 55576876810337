<template>
    <div>
        <b-card no-body class="mb-0">
            <div class="m-2">
                <b-row>
                    <b-col
                        cols="12"
                        md="3"
                        class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
                    >
                        <label>Show</label>
                        <v-select
                            v-model="perPage"
                            :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                            :options="perPageOptions"
                            :clearable="false"
                            class="per-page-selector d-inline-block mx-50 ml-1"
                        />
                    </b-col>
                    <b-col cols="12" md="9">
                        <b-row>
                            <b-col cols="12" md="3" class="mb-md-0 mb-2">
                                <v-select
                                    v-if="
                                        $can('read', 'buildTeamAccess') &&
                                        teamList
                                    "
                                    :options="teamList"
                                    :reduce="(option) => option.value"
                                    v-model="teamFilter"
                                    placeholder="Select Team"
                                    class="w-100"
                                />
                            </b-col>
                            <b-col cols="12" md="3" class="mb-md-0 mb-2">
                                <v-select
                                    v-if="
                                        $can('read', 'buildTeamAccess') &&
                                        developerList
                                    "
                                    v-model="developerFilter"
                                    :reduce="(option) => option.id"
                                    label="name"
                                    :options="developerList"
                                    :clearable="true"
                                    placeholder="Select Developer"
                                >
                                    <template #option="{ image, name }">
                                        <b-avatar
                                            size="18"
                                            :src="image"
                                            :text="avatarText(name)"
                                        />
                                        <span
                                            class="ml-50 d-inline-block align-middle"
                                        >
                                            {{ name }}</span
                                        >
                                    </template>
                                    <template
                                        #selected-option="{ image, name }"
                                    >
                                        <b-avatar
                                            size="18"
                                            :src="image"
                                            :text="avatarText(name)"
                                        />
                                        <span
                                            class="ml-50 d-inline-block align-middle"
                                        >
                                            {{ name }}</span
                                        >
                                    </template>
                                </v-select>
                            </b-col>
                            <b-col cols="12" md="2" class="mb-md-0 mb-2">
                                <v-select
                                    :options="statusOptions"
                                    :reduce="(option) => option.value"
                                    v-model="statusFilter"
                                    placeholder="Select Status"
                                    class="w-100"
                                />
                            </b-col>
                            <b-col cols="12" md="2" class="mb-md-0 mb-2">
                                <v-select
                                    :options="osOptions"
                                    v-model="osFilter"
                                    placeholder="Select OS"
                                    class="w-100"
                                />
                            </b-col>

                            <b-col cols="12" md="2" class="mb-md-0 mb-2">
                                <b-form-input
                                    v-model="searchQuery"
                                    class="d-inline-block mr-1"
                                    placeholder="Search..."
                                />
                            </b-col>
                        </b-row>
                    </b-col>
                </b-row>
            </div>

            <b-table
                ref="refBuildListTable"
                class="position-relative"
                :items="fetchBuilds"
                :fields="tableColumns"
                :table-class="'build-list-table table dataTable no-footer dtr-column'"
                responsive
                striped
                primary-key="id"
                :sort-by.sync="sortBy"
                show-empty
                empty-text="No matching records found"
                :sort-desc.sync="isSortDirDesc"
            >
                <template #table-busy>
                    <div class="text-center my-2">
                        <b-spinner class="d-block mx-auto mb-50"></b-spinner>
                        <strong>Loading...</strong>
                    </div>
                </template>
                <!-- Column: id -->
                <template #cell(id)="data">
                    <span class="align-text-top text-capitalize">{{
                        data.item.id
                    }}</span>
                </template>

                <!-- Column: OS -->
                <template #cell(OS)="data">
                    <social-icons
                        :socialType="data.item.os"
                        :size="'18'"
                    ></social-icons>
                </template>

                <!-- Column: id -->
                <template #cell(product_name)="data">
                    <b-media vertical-align="center">
                        <template #aside>
                            <b-avatar
                                rounded="sm"
                                size="32"
                                :src="
                                    backend_url +
                                    data.item.icon +
                                    '?v=' +
                                    data.item.id
                                "
                                :text="avatarText(data.item.product_name)"
                                :variant="`light-secondary`"
                            />
                        </template>
                        <span class="build-list-product-name">{{
                            data.item.product_name
                        }}</span>
                    </b-media>
                </template>

                <template #cell(user)="data">
                    <span
                        class="align-text-top text-capitalize"
                        v-if="data.item.user"
                        >{{ data.item.user.name }}</span
                    >
                    <span class="align-text-top text-capitalize" v-else>-</span>
                </template>
                <!-- Column: id -->
                <template #cell(Commit)="data">
                    <span class="align-text-top">
                        <b-badge
                            target="_blank"
                            :href="
                                'https://bitbucket.org/gameguru/' +
                                data.item.repo +
                                '/commits/' +
                                data.item.commit
                            "
                            variant="light-secondary"
                        >
                            <feather-icon icon="LinkIcon" class="mr-25" />
                            <span>{{ data.item.commit.substring(0, 6) }}</span>
                        </b-badge>
                    </span>
                </template>
                <!-- Column: id -->
                <template #cell(debug)="data">
                    <span class="align-text-top text-capitalize">
                        <b-badge
                            variant="light-success"
                            v-if="data.item.debug == 0"
                            >Release</b-badge
                        >
                        <b-badge variant="light-warning" v-else>Debug</b-badge>
                        <b-badge
                            variant="light-success ml-25"
                            v-if="data.item.aab == 1"
                            >aab</b-badge
                        >
                      <template v-if="data.item.extraArguments">
                         <b-badge class="ml-25" variant="light-secondary" v-if="getCustomName(data.item.extraArguments)">{{getCustomName(data.item.extraArguments)}}</b-badge>
                      </template>
                    </span>
                </template>
                <!-- Column: id -->
                <template #cell(Unity_version)="data">
                    <span class="align-text-top text-capitalize">{{
                        data.item.editor_version
                    }}</span>
                </template>
                <!-- Column: id -->
                <template #cell(Version)="data">
                    <span class="align-text-top text-capitalize">{{
                        data.item.bundle_version
                    }}</span>
                </template>
                <!-- Column: id -->
                <template #cell(Status)="data">
                    <span class="align-text-top text-capitalize">
                        <div
                            title="Succeeded"
                            class="avatar avatar-status bg-light-success"
                            v-if="data.item.status == 'succeeded'"
                        >
                            <span class="avatar-content">
                                <feather-icon icon="CheckIcon" size="16"
                            /></span>
                        </div>

                        <div
                            title="Waiting"
                            class="avatar avatar-status bg-light-warning"
                            v-if="data.item.status == 'waiting'"
                        >
                            <span class="avatar-content">
                                <feather-icon icon="ClockIcon" size="16"
                            /></span>
                        </div>

                        <div
                            title="In Progress"
                            class="avatar avatar-status bg-light-info"
                            v-if="data.item.status == 'inprogress'"
                        >
                            <span class="avatar-content">
                                <feather-icon icon="PlayIcon" size="16"
                            /></span>
                        </div>

                        <div
                            title="Failed"
                            class="avatar avatar-status bg-light-danger"
                            v-if="data.item.status == 'failed'"
                        >
                            <span class="avatar-content">
                                <feather-icon icon="XIcon" size="16"
                            /></span>
                        </div>
                    </span>
                </template>
                <!-- Column: Time -->
                <template #cell(Time)="data">
                    <span class="align-text-top text-capitalize">{{
                        data.item.created_at
                    }}</span>
                </template>

                <template #cell(actions)="data">
                    <div class="d-flex align-items-center col-actions">
                        <div
                            v-if="
                                data.item.status === 'succeeded' ||
                                data.item.status === 'failed'
                            "
                        >
                            <div v-if="data.item.os === 'android'">
                                <a
                                    target="_blank"
                                    class="mr-1"
                                    v-if="data.item.appetize_key"
                                    :href="`https://appetize.io/app/${data.item.appetize_key}?device=pixel4&scale=100&orientation=portrait&osVersion=12.0&autoplay=true&grantPermissions=true&debug=true`"
                                    v-b-tooltip.hover.top="'Try Game'"
                                >
                                    <feather-icon icon="PlayIcon" size="18" />
                                </a>

                                <b-link
                                    class="mr-1"
                                    :to="{
                                        name: 'build-detail',
                                        params: { build: data.item.id },
                                    }"
                                    v-b-tooltip.hover.top="'View Build Logs'"
                                >
                                    <feather-icon
                                        icon="ActivityIcon"
                                        size="18"
                                    />
                                </b-link>

                                <b-dropdown
                                    variant="link"
                                    no-caret
                                    v-if="data.item.status === 'succeeded'"
                                    class="mr-1"
                                    id="dShareButton"
                                    v-b-tooltip.hover.top="
                                        'Download or Copy APK'
                                    "
                                >
                                    <template #button-content>
                                        <feather-icon
                                            icon="DownloadIcon"
                                            size="16"
                                            class="align-middle text-body"
                                        />
                                    </template>
                                    <b-dropdown-item
                                        @click="
                                            () => downloadBuild(data.item.id)
                                        "
                                    >
                                        <feather-icon icon="DownloadIcon" />
                                        <span class="align-middle ml-50"
                                            >Download</span
                                        >
                                    </b-dropdown-item>
                                    <b-dropdown-item
                                        @click="() => shareBuild(data.item.id)"
                                    >
                                        <feather-icon icon="Share2Icon" />
                                        <span class="align-middle ml-50"
                                            >Copy Link</span
                                        >
                                    </b-dropdown-item>
                                </b-dropdown>

                                <b-link
                                    class="mr-1"
                                    v-on:click="
                                        confirmRestartBuild(data.item.id)
                                    "
                                    v-b-tooltip.hover.top="'Restart Build'"
                                >
                                    <feather-icon
                                        icon="RefreshCcwIcon"
                                        size="18"
                                    />
                                </b-link>
                            </div>
                            <div v-else>
                                <b-link
                                    class="mr-1"
                                    :to="{
                                        name: 'build-detail',
                                        params: { build: data.item.id },
                                    }"
                                    v-b-tooltip.hover.top="'View Build Logs'"
                                >
                                    <feather-icon
                                        icon="ActivityIcon"
                                        size="18"
                                    />
                                </b-link>
                                <b-link
                                    class="mr-1"
                                    v-on:click="
                                        confirmRestartBuild(data.item.id)
                                    "
                                    v-b-tooltip.hover.top="'Restart Build'"
                                >
                                    <feather-icon
                                        icon="RefreshCcwIcon"
                                        size="18"
                                    />
                                </b-link>
                            </div>
                        </div>
                        <div v-else-if="data.item.status === 'waiting'">
                            <b-link
                                class="mr-1"
                                v-on:click="deleteBuild(data.item.id)"
                                v-b-tooltip.hover.top="'Cancel Build'"
                            >
                                <feather-icon icon="TrashIcon" size="18" />
                            </b-link>
                        </div>
                        <div v-else-if="data.item.status === 'inprogress'">
                            <b-link
                                class="mr-1"
                                :to="{
                                    name: 'build-detail',
                                    params: { build: data.item.id },
                                }"
                                v-b-tooltip.hover.top="'View Build Logs'"
                            >
                                <feather-icon icon="ActivityIcon" size="18" />
                            </b-link>
                        </div>
                        <b-link
                            v-if="
                                $can('read', 'qaSend') &&
                                data.item.status == 'succeeded' &&
                                data.item.qa == 0
                            "
                            v-on:click="createQATask(data.item.id)"
                            v-b-tooltip.hover.top="'Send QA'"
                            >QA
                        </b-link>
                        <div
                            v-if="
                                data.item.status == 'succeeded' &&
                                data.item.qa == 1
                            "
                            v-b-tooltip.hover.top="'QA Sent'"
                            class="text-success"
                        >
                            QA
                        </div>
                    </div>
                </template>
            </b-table>

            <div class="mx-2 mb-2">
                <b-row>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-start"
                    >
                        <span class="text-muted"
                            >Showing {{ dataMeta.from }} to {{ dataMeta.to }} of
                            {{ dataMeta.of }} entries</span
                        >
                    </b-col>
                    <b-col
                        cols="12"
                        sm="6"
                        class="d-flex align-items-center justify-content-center justify-content-sm-end"
                    >
                        <b-pagination
                            v-model="currentPage"
                            :total-rows="totalBuilds"
                            :per-page="perPage"
                            first-number
                            last-number
                            class="mb-0 mt-1 mt-sm-0"
                            prev-class="prev-item"
                            next-class="next-item"
                        >
                            <template #prev-text>
                                <feather-icon
                                    icon="ChevronLeftIcon"
                                    size="15"
                                />
                            </template>
                            <template #next-text>
                                <feather-icon
                                    icon="ChevronRightIcon"
                                    size="15"
                                />
                            </template>
                        </b-pagination>
                    </b-col>
                </b-row>
            </div>
        </b-card>

        <b-modal
            id="modal-center"
            centered
            title="Copy Download Link"
            hide-footer
        >
            <div class="d-flex">
                <b-form-group class="mb-0 mr-1 w-100">
                    <b-form-input v-model="buildDownloadLink" readonly />
                </b-form-group>
                <b-button
                    v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                    variant="primary"
                    @click="doCopy"
                >
                    Copy!
                </b-button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BMedia,
    BAvatar,
    BLink,
    BBadge,
    BDropdown,
    BDropdownItem,
    BPagination,
    VBTooltip,
    BSpinner,
    BFormGroup,
    BCardText,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import vSelect from "vue-select";
import useBuildManagerApi from "@/composables/useBuildManagerApi";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { avatarText } from "@core/utils/filter";
import SocialIcons from "@/components/SocialIcons.vue";

export default {
    components: {
        BSpinner,
        BCard,
        BRow,
        BCol,
        BFormInput,
        BButton,
        BTable,
        BMedia,
        BAvatar,
        BLink,
        BBadge,
        BDropdown,
        BDropdownItem,
        BPagination,
        vSelect,
        BFormGroup,
        BCardText,
        SocialIcons,
    },
    directives: {
        "b-tooltip": VBTooltip,
        Ripple,
    },
    data() {
        return {
            buildDownloadLink: "",
            backend_url: process.env.VUE_APP_BACKEND_URL,
        };
    },
    methods: {
        shareBuild(id) {
            this.$store
                .dispatch("buildStore/downloadBuild", {
                    id: id,
                })
                .then((response) => {
                    this.buildDownloadLink = response.data.data[0];
                    this.$root.$emit("bv::show::modal", "modal-center");
                    this.doCopy();
                })
                .catch((error) => {
                    this.buildDownloadLink = "";
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Not found.",
                            icon: "AlertTriangleIcon",
                            variant: "info",
                            text: "If you think there is an error, please contact the admin.",
                        },
                    });
                });
        },
        getCustomName (text){
            const match = /-customBuild\s+(\w+)/.exec(text);
            return match ? match[1] : false;
        },
        async doCopy() {
            try {
                await navigator.clipboard.writeText(this.buildDownloadLink);
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Link copied",
                        icon: "BellIcon",
                    },
                });
            } catch {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Can not copy!",
                        icon: "BellIcon",
                    },
                });
            }
        },
    },
    mounted() {
        this.fetchDevelopers();
        if (this.$can("read", "buildTeamAccess")) {
            this.fetchTeamList();
        }
    },
    setup() {
        const {
            fetchBuilds,
            tableColumns,
            perPage,
            currentPage,
            totalBuilds,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refBuildListTable,
            refetchData,
            osOptions,
            statusOptions,
            osFilter,
            statusFilter,
            downloadBuild,
            confirmRestartBuild,
            deleteBuild,
            createQATask,
            teamFilter,
            teamList,
            fetchTeamList,
            fetchDevelopers,
            developerList,
            developerFilter,
        } = useBuildManagerApi();

        return {
            fetchBuilds,
            tableColumns,
            perPage,
            currentPage,
            totalBuilds,
            dataMeta,
            perPageOptions,
            searchQuery,
            sortBy,
            isSortDirDesc,
            refBuildListTable,
            refetchData,
            osOptions,
            statusOptions,
            osFilter,
            statusFilter,
            downloadBuild,
            confirmRestartBuild,
            deleteBuild,
            createQATask,
            teamFilter,
            teamList,
            fetchTeamList,
            fetchDevelopers,
            developerList,
            developerFilter,
            avatarText,
        };
    },
};
</script>

<style lang="scss">
.per-page-selector {
    width: 90px;
}

#dShareButton__BV_toggle_ {
    padding: 0px !important;
}

.build-list-product-name {
    line-height: 32px;
}
</style>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
